.header {
    position: fixed;
    top: 0px;
    // z-index: 1;
    background-color: var(--body-color);
    width: 100%;
    height: $nav-height;
    // box-shadow: 0 10px 30px -10px rgba(2,12,27,0.7);
}

.nav {
    position: relative;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
    transform: translate(0px);
    overflow: hidden;
}

.nav-active {
    left: 0;
    transition: all 0.5s;
    transform: translate(-10rem);
}
