/* GENERAL STYLES */
.visuallyHidden {
    position: absolute; 
    overflow: hidden; 
    clip: rect(0 0 0 0); 
    height: 1px; width: 1px; 
    margin: 0px; padding: 0; border: 0; 
}

.hamburger-container {
    position: fixed;
    top: calc(1 / 2 * 100px - 10px);
    right: 50px;
}

.hamburger {
    cursor: pointer;
    margin: 0 auto;
    width: 20px;
    height: 20px;
    position: relative;
}

.hamburger .bar {
    padding: 0px;
    width: 20px;
    height: 2.2px;
    background-color: #f0f0f0a1;
    display: block;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    position: absolute; 
}

.bar1 {
    top: 0;
}

.bar2,
.bar3 {
    top: 9px;
    background-color: #f0f0f061 !important;
}

.bar3 {
    right: 0;
}

.bar4 {
    bottom: 0;
}

/* HAMBURGER 2 */

.checkbox2:checked + label > .hamburger2 > .bar1{
    transform: translateX(40px);
    background-color: transparent;
}

.checkbox2:checked + label > .hamburger2 > .bar2{
    transform: rotate(45deg);
}

.checkbox2:checked + label > .hamburger2 > .bar3{
    transform: rotate(-45deg);
}

.checkbox2:checked + label > .hamburger2 > .bar4{
    transform: translateX(-40px);
    background-color: transparent;
}
