:root {
	--dark-color: 		#323A45;

    --color-fff:		#FFFFFF;
	--color-100:		#F0F0F0;
	--color-200:		#EEEEEE;
	--color-300:		#E0E0E0;
	--color-400:		#BDBDBD;
	--color-500:		#555555;
	--color-600:		#757575;
	--color-700:		#616161;
	--color-800:		#424242;
	--color-900:		#212121;
	--color-000:		#000000;

	--border-color:		#f0f0f0;
	--card-color: 		#ffffff;
	--body-color: 		#f9fbfd;
	--white-color: 		#ffffff;
	--sidebar-color:	#fcfcfc;
    --text-color:       #212529;
	--svg-color: 		#76808F;

	--hc-font-color:	#555555;
	--hc-bg-color:		#f0f0f0;

    --primary-color: 	#feb019;
	--secondary-color: 	#A0E7E5;

}


[data-theme="dark"] {
	--dark-color: 		#323A45;

	--color-fff:		#000000;
	--color-100:		#212121;
	--color-200:		#262727;
	--color-300:		#616161;
	--color-400:		#757575;
	--color-500:		#8f8f8f;
	--color-600:		#BDBDBD;
	--color-700:		#E0E0E0;
	--color-800:		#EEEEEE;
	--color-900:		#F0F0F0;
	--color-000:		#FFFFFF;

	--border-color:		#353535;
	--card-color: 		#262727;
	--body-color: 		#282c34;
	--white-color: 		#ffffff;
	--sidebar-color:	#fcfcfc;
    --text-color:       #8f8f8f;

	--hc-font-color:	#555555;
	--hc-bg-color:		#f0f0f0;

    [class="theme-cyan"] {
		--primary-color: 	#00bdaa;
	}

	[class="theme-indigo"] {
		--border-color:		#212020;
		--card-color: 		#101010;
		--body-color: 		#050505;
	}

	[class="theme-blue"] {
		--primary-color: 	#7258db;
        --border-color:		#1a1f2d;
		--card-color: 		#10141f;
		--body-color: 		#080b13;
	}
	
	[class="theme-orange"] {
        --border-color:		#092940;
		--card-color: 		#052133;
		--body-color: 		#051c2c;
	}
	
	[class="theme-blush"] {
        --border-color:		#242331;
		--card-color: 		#1a1925;
		--body-color: 		#15141f;
	}
	
	[class="theme-red"] {
		--primary-color: 	#f73e60;
        --border-color:		#222335;
		--card-color: 		#161725;
		--body-color: 		#11121d;
    }
}
