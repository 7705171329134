body {
    @include transition(ease .1s);
    margin: 0 !important;
    background-color: var(--body-color);
    min-height: 100vh;
    color: var(--color-500);
    font-weight: font-weight-normal;
    // font-family: $font-Plex;
    // font-family: $font-poppins;
    // font-family: $font-opensans;
    // font-family: $font-montserrat;
    font-size: 15px;

    &.font-opensans {
        font-family: $font-opensans;
    }

    &.font-poppins {
        font-family: $font-poppins;
        font-size: 15px;
    }

    &.font-montserrat {
        font-family: $font-montserrat;
        font-size: 15px;
    }

    &.font-Plex {
        font-family: $font-Plex;
        font-size: 15px;
    }

    @media only screen and (max-width: 554px) {
        font-size: 13px;
    }

    a {
        color: var(--text-color);
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: var(--secondary-color);
        }
    }
}
