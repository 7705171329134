//Fonts Family
$font-family: 		  'IBM Plex Sans', sans-serif;
$font-opensans: 	  'Open Sans', sans-serif;
$font-poppins: 	      'Poppins', sans-serif;
$font-montserrat: 	  'Montserrat', sans-serif;
$font-Plex:           'IBM Plex Sans', sans-serif;

// Fonts
$font-size: 			14px;
$text-color:            var(--font-color);

$nav-height:            100px;
